import React from 'react'

import LeftDrawer from './LeftDrawer'
import RightDrawer from './RightDrawer'
import { SidebarProvider } from '../../../../contexts/Old_SidebarContext'
import { Grid, Button, Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

function Editor() {
  const navigate = useNavigate()

  function parseQueryString() {
    const queryString = window.location.search.substring(1)
    // console.log('queryString', queryString)
    const pairs = queryString.split('&')
    // console.log('pairs', decodeURIComponent(pairs))
    const parsed = {}

    for (let i = 0; i < pairs.length; i++) {
      const [key, value] = pairs[i].split('=')
      parsed[decodeURIComponent(key)] = decodeURIComponent(value)
    }

    // console.log('parsed', parsed)
    return parsed
  }

  const queryParams = parseQueryString()
  // console.log('queryParams', queryParams)

  const objectFromQuery = queryParams
  // console.log('objectFromQuery', objectFromQuery)

  return (
    <>
      <Typography variant='h4' textAlign={'center'}>
        Edit Response
      </Typography>
      <SidebarProvider position='relative'>
        <Box>
          <Button
            style={{
              position: 'absolute',
              top: '18px',
              right: '2px',
              color: '#777',
              // backgroundColor: '#da0404',
              zIndex: '10000',
              padding: '1px 5px',
              width: '100px',
            }}
            onClick={() =>
              navigate(objectFromQuery?.hId ? `/NewChat/${objectFromQuery?.hId}` : '/NewChat')
            }
          >
            <ArrowBackIcon /> Back
          </Button>
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '100vh',
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={6}>
              <LeftDrawer />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RightDrawer ans={objectFromQuery?.ans} />
            </Grid>
          </Grid>
        </Box>
      </SidebarProvider>
    </>
  )
  // return (
  //   <Box>
  //     <LeftDrawer />
  //     {/* <Button
  //       style={{
  //         position: 'absolute',
  //         zIndex: '10000',
  //         top: '.9rem',
  //         left: '50%',
  //         color: '#ffdb49',
  //         backgroundColor: '#da0404',
  //         width: '5rem',
  //         padding: '.25rem',
  //         transform: 'translate(-50%)',
  //       }}
  //     >
  //       Back
  //     </Button> */}
  //     <RightDrawer />
  //   </Box>
  // )
}

export default Editor
